import React from "react";

const BackGround = () => {
  return (
    <div>
      <div className="absolute inset-0 ml-[-160px] overflow-hidden bg-black flex justify-between gap-[12px] sm:gap-[16px] -mt-96 sm:justify-start">
        {Array.from({ length: 20 }, (_, index) => index + 1).map((item) => {
          return (
            <>
              <div className="ml-auto w-[100px] sm:w-[306px]  flex-none space-y-[12px] sm:space-y-[16px] pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-[5rem]">
                {Array.from({ length: 30 }, (_, index) => index + 1).map(
                  (item) => {
                    return (
                      <>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/4.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/5.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>

                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/6.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/7.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              <div className="mr-auto  w-[100px] sm:w-[306px] flex-none space-y-[12px] sm:space-y-[16px] sm:mr-0 sm:pt-52 lg:pt-36">
                {Array.from({ length: 30 }, (_, index) => index + 1).map(
                  (item) => {
                    return (
                      <>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/8.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/9.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/1.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/2.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              <div className=" w-[100px] sm:w-[306px] flex-none space-y-[12px] sm:space-y-[16px] pt-32 sm:pt-0">
                {Array.from({ length: 30 }, (_, index) => index + 1).map(
                  (item) => {
                    return (
                      <>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/3.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/4.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/5.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative flex h-[130px] sm:h-[320px]">
                          <img
                            src="/assets/images/6.gif"
                            alt=""
                            className="aspect-[2/3] w-full h-full bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0  ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default BackGround;
