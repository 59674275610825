import React from 'react'
import { IconCominsoon, Rectangle92 } from '../../../assets'

const ComingSoonMobile = () => {
    return (
        <>
            <div className='relative comingsoon overflow-hidden font-josefin-sans h-[89.9vh]'>
                <img src={Rectangle92} alt='Rectangle92' className='absolute top-0 left-0 w-full h-full object-cover' />
                <div className='absolute top-0 left-0 w-full h-full bg-black opacity-55'></div>

                <div className='flex justify-center text-center'>
                    <div className='relative z-10 text-white'>
                        <div className="flex items-center justify-center text-center xxs:mt-16 xs:mt-16 xss:mt-12 gap-3">
                            <hr className="border-[1px] xxs:w-[48px] xs:w-[52px] xss:w-[52px] " />
                            <p className=" xxs:text-[28px] xs:text-[30px] xss:text-[40px] font-light text-white">Coming Soon</p>
                            <hr className="border-[1px] xxs:w-[48px] xs:w-[52px] xss:w-[52px]" />
                        </div>

                        <div className='xxs:text-[25px] xs:text-[26px] xss:text-[32px] font-bold leading-relaxed mt-5'>
                            <p>Get Notified</p>
                            <p>When We Launch</p>
                        </div>

                        <div className='flex items-center justify-center xxs:mt-7 xs:mt-5'>
                            <div className='flex items-center xxs:w-[283.8px] xs:w-[320px] xss:w-[343.8px] h-[47px] rounded-[47px] border-2 border-white bg-transparent'>
                                <input
                                    type='email'
                                    placeholder='Enter your email address'
                                    className='flex-grow h-full rounded-l-[47px] border-none text-white placeholder-white bg-transparent px-3 xs:px-5 focus:outline-none xxs:text-[14px] xs:text-[16px] xss:text-[18px]'
                                />
                                <button className='text-[13px] xss:text-[14px] xxs:w-[85px] xs:w-[105px] xss:w-[85px] h-[30px] rounded-[47px] bg-white hover:bg-slate-100 text-black mr-2'>
                                    Notify Me
                                </button>
                            </div>
                        </div>
                        <p className='xxs:text-[13px]  xss:text-[14px] my-5'>* Get Discounts & Other Goodies For Joining The Waitlist :)</p>

                        <div className='flex justify-center items-center xxs:mt-[90px] xs:mt-[100px] xss:mt-[70px]'>
                            <img src={IconCominsoon} alt='IconCominsoon' className='w-[249.64px] xss:w-[279.64px]  h-[60px] xss:h-[68px]' />
                        </div>

                        <div className='mt-32 xs:mt-32 xss:mt-40 flex flex-col justify-center items-center'>
                            <hr className='border-1 border-white w-[284px] xs:w-[334px] xss:w-[364px]' />
                            <div className='flex text-center justify-center gap-5 xss:gap-7 text-white text-[15px] font-normal my-4 xss:my-5'>
                                <p>Terms of Service</p>
                                <p>Privacy Policy</p>
                                <p>Cookie Policy</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default ComingSoonMobile